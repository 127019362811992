<template>
  <v-card flat class="transparent mx-auto" width="720">
    <h5 class="mb-12">
      <v-icon>$pineapple</v-icon>
      <small style="color: #092">pineapple.net.au</small>
    </h5>
    <table class="pineapple-general-info mb-12" width="100%">
      <tbody v-if="ready">
        <tr v-for="(value, key) in info" :key="key">
          <td style="text-align: right" width="160">
            {{ key === 'faceBook' ? 'facebook' : key === 'linkedIn' ? key : key === 'officeABN' ? 'Office ABN' : key.transformToTitle() }}
          </td>
          <td>
            <v-text-field
              :value="value"
              @input="updates[key]($event)"
              outlined
              dense
              hide-details
            />
          </td>
        </tr>
      </tbody>
    </table>

    <v-row justify="center">
      <v-btn @click="saveData" dark class="primary">
        update/save
      </v-btn>
    </v-row>

  </v-card>
</template>

<script>

export default {
  name: 'GeneralInfo',

  data: () => ({
    ready: false,
    info: {},
    updates: {}
  }),

  methods: {
    getData (data) {
      const { 'search-address-popup': messages, ...info } = data
      Object.assign(this, { info })

      for (const key in info) {
        Object.assign(this.updates, {
          [key]: function (key, val) {
            Object.assign(this.info, { [key]: val })
          }.bind(this, key)
        })
      }

      this.ready = true
    },

    saveData () {
      this.__updatePineappleGeneralInfo(this.info, this.saveCallback)
    },

    saveCallback (response) {
    }
  },

  created () {
    this.__getPineappleGeneralInfo(this.getData)
  }
}
</script>

<style>
table.pineapple-general-info {
  border-spacing: 32px 0;
}
</style>
